import styled from 'styled-components';

import { IconByName as IconByNameComponent } from '+/components/Icon';

export const IconByName = styled(IconByNameComponent)`
  margin-right: 0.6em;
  margin-left: -0.6em;

  color: var(--color-privacy-policy-link-text-icon);
`;

export const PrivacyPolicyLink = styled.span`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;
