import * as yup from 'yup';

import { t } from '+/utils/textDictionary';
import { urlPattern } from '+/utils/sanitizeFormInputs';

const checkForUrls = () => ({
  name: 'urlSanitized',
  message: ({ label }) => t('EAAdvocacyForm.URLSanitized', { label: label.toLowerCase() }),
  test: value => !urlPattern.test(value),
});

const validator = () =>
  yup.object().shape({
    Prefix: yup.string().label(t('EAAdvocacyForm.Prefix')).test(checkForUrls()),
    FirstName: yup.string().label(t('EAAdvocacyForm.FirstName')).test(checkForUrls()).required(),
    MiddleName: yup.string().label(t('EAAdvocacyForm.MiddleName')).test(checkForUrls()),
    LastName: yup.string().label(t('EAAdvocacyForm.LastName')).test(checkForUrls()).required(),
    Suffix: yup.string().label(t('EAAdvocacyForm.Suffix')).test(checkForUrls()),
    EmailAddress: yup.string().email().label(t('EAAdvocacyForm.EmailAddress')).required(),
    MobilePhone: yup.string().label(t('EAAdvocacyForm.MobilePhone')).test(checkForUrls()),
    PostalCode: yup.string().label(t('EAAdvocacyForm.PostalCode')).test(checkForUrls()),
    Country: yup.string().required().label(t('EAAdvocacyForm.Country')),
    Message0: yup.string().min(6).required().label(t('EAAdvocacyForm.Message')),
    Subject0: yup.string().min(6).required().label(t('EAAdvocacyForm.Subject')),
    YesSignMeUpForUpdatesForBinder: yup.boolean().label(t('EAAdvocacyForm.InfoConsent')),
    SmsSubscribeMobilePhone: yup.boolean().label(t('EAAdvocacyForm.InfoConsentSms')),
    AcceptedPrivacyPolicy: yup.boolean().when('Country', {
      is: 'US',
      otherwise: yup
        .boolean()
        .label(
          `${t('EAAdvocacyForm.AcceptPrivacyPolicyPrefix')} ${t(
            'EAAdvocacyForm.AcceptPrivacyPolicyLinkText',
          )}`,
        )
        .required(t('EAAdvocacyForm.AcceptPrivacyPolicyErrorNotChecked'))
        .isTrue(t('EAAdvocacyForm.AcceptPrivacyPolicyErrorNotChecked')),
    }),
  });

export default validator;
