import React from 'react';

import * as Styled from './FormNavigation.styles';
import { BackButton } from './BackButton.component';

import { NextStep } from '+/components/DonateForm/widgets/nextStep.component';
import { InitialContinueButton } from '+/components/DonateForm/widgets/PaymentMethodSelector';
import { SecurityTagline } from '+/components/SecurityTagline';
import { FormSmsDisclaimer } from '+/components/FormSmsDisclaimer';

interface Props {
  controller: EveryActionModelController;
  step: 'initial' | 'other';
}

export const NextStepButtonSet = ({ controller, step = 'other' }: Props) => {
  const smsOptInVisible =
    controller.isUsMobilePhone() && controller.isUsMailingAddress() && step === 'other';
  return (
    <Styled.Wrapper>
      <Styled.NextButtonWrapper>
        {step === 'initial' && <InitialContinueButton controller={controller} />}
        {step === 'other' && <NextStep controller={controller} />}
      </Styled.NextButtonWrapper>
      <BackButton controller={controller} initialStep={step === 'initial'} />
      <Styled.Disclaimers>
        <SecurityTagline />
        {smsOptInVisible && <FormSmsDisclaimer />}
      </Styled.Disclaimers>
    </Styled.Wrapper>
  );
};
