import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: center;
  line-height: 1;

  svg {
    margin: 0 0.25rem 0 0;
  }
`;
