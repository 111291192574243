import styled from 'styled-components';

import { Markdown as MarkdownComponent } from '+/components/Markdown';
import { textH1, textH2, textH3 } from '+/styles/reusable/textStyles';

export const Markdown = styled(MarkdownComponent)`
  text-align: center;

  h1 {
    ${textH1}
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      position: absolute;
      z-index: -1;
      opacity: 0.375;
      background: var(--color-theme-base-inverse);
      box-shadow: 0 0 1.75rem 1.75rem var(--color-theme-base-inverse);
      transform: scale(0.75, 0.5);
    }
  }

  h2 {
    ${textH2}
  }

  h3 {
    ${textH3}
  }

  button {
    margin: 0 auto;
  }

  & > *:not(:last-child),
  p:not(:last-child) {
    margin-bottom: 1rem;

    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      margin-bottom: 1.875rem;
    }
  }
`;

export const PrivacyPolicyText = styled.span``;

export const Disclaimers = styled.div`
  font-family: var(--font-primary);
  font-size: 0.75rem;
  color: var(--color-privacy-policy-link-text);
  text-align: center;
  padding-inline: var(--form-side-padding);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  a {
    color: inherit;

    &:hover,
    &:focus-visible {
      color: var(--color-neutral-800);
    }

    &:active {
      color: var(--color-black);
    }
  }
`;
