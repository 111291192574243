import React, { ComponentType, useContext, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import { DEFAULT_TRUNCATE_HEIGHT } from '../Truncate/Truncate.component';
import { getOrCreateRootIfNotExists } from '../Modal/ModalPortal.component';
import { FORM_TOP_PADDING } from '../FormWrapper/FormWrapper.styles';
import { TRUNCATE_BUTTON_HEIGHT } from '../Truncate/Truncate.styles';
import { FormSmsDisclaimer } from '../FormSmsDisclaimer';

import { PrivacyPolicyLink } from './PrivacyPolicyLink';
import * as Styled from './EveryActionPetitionForm.styles';
import { EditYourMessageModalButton } from './EditYourMessageModalButton';

import * as FormStyled from '+/styles/styledComponents/Form.styles';
import {
  getTwoToneTheming,
  FormBaseShades,
  FormBackgroundStyles,
} from '+/styles/styledComponents/Form.styles';
import { ColorTheme } from '+/components/ColorTheme';
import { Markdown } from '+/components/EveryActionPetitionForm/EveryActionPetitionForm.styles';
import { Button } from '+/components/Button';
import { Progress } from '+/components/Progress';
import { Link } from '+/components/Link';
import { NameSplitter } from '+/components/NameSplitter';
import { Tooltip } from '+/components/Tooltip';
import { Truncate } from '+/components/Truncate';
import { LanguageContext } from '+/contexts/LanguageContext';
import {
  ColorThemeContext,
  handleDeleteUserData,
  initializeStateWithCookieVal,
  postUserData,
} from '+/contexts';
import FormikNameController from '+/utils/FormikNameController';
import { t } from '+/utils/textDictionary';
import { useCountries } from '+/utils/hooks/useCountries';
import { useEveryActionFormProgress } from '+/utils/hooks/useEveryActionFormProgress';
import { useOnScreen } from '+/utils/useOnScreen';
import scrollToLocation from '+/utils/scrollToLocation';
import { useIsMobile } from '+/utils/responsive';
import { useScrollPosition } from '+/utils/useScrollPosition';
import { useDisplayRememberMe } from '+/contexts/RememberMe';

export const EveryActionPetitionForm = (props: EveryActionPetitionFormProps) => {
  const {
    everyActionPetitionFormData,
    advocacyFields,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    suppliedOptions,
    setFieldValue,
    formType,
    formHeaderRef,
  } = props;
  const isUS = values.Country === 'US';
  const displayRememberMe = useDisplayRememberMe();
  const displaySmsOptIn = values.MobilePhone && isUS;

  const controller = new FormikNameController({ values, setFieldValue });
  const isAdvocacyForm = formType === 'AdvocacyForm';
  const hasMessage = isAdvocacyForm;
  const countriesList = useCountries();
  const language: string = useContext(LanguageContext);

  // Determine when to display catch-all form error
  const formHasErrors = Object.values(errors).some(errorMessage => errorMessage);
  const [displayFormError, setDisplayFormError] = useState(false);

  useEffect(() => {
    if (!formHasErrors) setDisplayFormError(false);
    return () => setDisplayFormError(false);
  }, [formHasErrors]);

  // Determine whether and when form background color should transition
  const [scrolled, setScrolled] = useState(false);
  const petitionFieldsRef = useRef(null);
  const petitionFieldsOnScreen = useOnScreen(petitionFieldsRef, 0, 0.1, scrolled);

  const parentColorTheme = useContext(ColorThemeContext);
  const { accentedColorTheme, formColorTheme, formBaseShade } = getTwoToneTheming(
    everyActionPetitionFormData.formBackgroundStyle,
    parentColorTheme,
  );
  const [formColorTransitioned, setFormColorTransitioned] = useState(true);

  useScrollPosition(({ currPos }) => {
    if (currPos.y && !scrolled) setScrolled(true);
  });

  useEffect(() => {
    const shouldFormColorTransition =
      petitionFieldsOnScreen &&
      everyActionPetitionFormData.formBackgroundStyle !== FormBackgroundStyles.Default;

    if (shouldFormColorTransition && !formColorTransitioned) setFormColorTransitioned(true);
    else setFormColorTransitioned(false);
  }, [petitionFieldsOnScreen]);

  const [rememberMeChecked, updateRememberMeChecked] = useState(isUS);

  useEffect(() => {
    if (isUS) {
      updateRememberMeChecked(true);
    } else {
      initializeStateWithCookieVal(() => updateRememberMeChecked(true));
    }
  }, [isUS]);

  const handleRememberMeChange = () => {
    updateRememberMeChecked(!rememberMeChecked);
  };

  return (
    <Styled.PetitionFormContent
      colorTheme={formColorTransitioned ? accentedColorTheme : formColorTheme}
      baseShade={formColorTransitioned ? FormBaseShades.Dark : formBaseShade}
    >
      <FormStyled.Container>
        <div ref={formHeaderRef} id="take-action">
          <Styled.PetitionFormHeader>
            <Markdown>{everyActionPetitionFormData.formHeader}</Markdown>
          </Styled.PetitionFormHeader>
        </div>
        {hasMessage && (
          <EditYourMessageModalButton
            values={values}
            handleBlur={handleBlur}
            errors={errors}
            handleChange={handleChange}
            touched={touched}
            intro={advocacyFields.intro}
            outro={advocacyFields.outro}
          />
        )}
        {everyActionPetitionFormData.formText && (
          <FormStyled.Text>
            <Markdown>{everyActionPetitionFormData.formText}</Markdown>
          </FormStyled.Text>
        )}
        <Styled.PetitionFields ref={petitionFieldsRef}>
          <FormStyled.Body className="pet-form__body">
            <FormStyled.Section className="pet-form__section">
              <FormStyled.CustomFieldRow
                style={{ flexWrap: 'wrap', justifyContent: 'space-between' }}
              >
                {typeof values.Prefix !== 'undefined' && (
                  <FormStyled.InputLabel
                    style={{ width: '25%', flexGrow: 1, flexBasis: '15%' }}
                    floating
                    errored={!!errors.Prefix}
                  >
                    <FormStyled.TextyInput
                      name="Prefix"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.Prefix}
                      autoComplete="Prefix"
                      placeholder={t('EAPetitionForm.Prefix')}
                    />
                    <FormStyled.InputLabelText floating>
                      {t('EAPetitionForm.Prefix')}
                    </FormStyled.InputLabelText>
                    {touched.Prefix && errors.Prefix && (
                      <FormStyled.InputError>{errors.Prefix}</FormStyled.InputError>
                    )}
                  </FormStyled.InputLabel>
                )}
                <FormStyled.InputLabel
                  style={{ width: '35%', flexGrow: 1, flexBasis: '50%' }}
                  floating
                  errored={!!errors.FirstName}
                >
                  <FormStyled.TextyInput
                    name="FirstName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.FirstName}
                    autoComplete="given-name"
                    placeholder={`${t('EAPetitionForm.FirstName')}*`}
                  />
                  <FormStyled.InputLabelText floating>
                    {t('EAPetitionForm.FirstName')}
                    <sup>*</sup>
                  </FormStyled.InputLabelText>
                  {touched.FirstName && errors.FirstName && (
                    <FormStyled.InputError>{errors.FirstName}</FormStyled.InputError>
                  )}
                  <NameSplitter
                    controller={controller}
                    namePartToCheck="FirstName"
                    namePartToSplit="Prefix"
                    position="start"
                    nameLabel={t('EAPetitionForm.Prefix')}
                  />
                  <NameSplitter
                    controller={controller}
                    namePartToCheck="FirstName"
                    namePartToSplit="MiddleName"
                    position="end"
                    nameLabel={t('EAPetitionForm.MiddleName')}
                  />
                </FormStyled.InputLabel>
                {typeof values.MiddleName !== 'undefined' && values.MiddleName && (
                  <FormStyled.InputLabel
                    style={{ width: '45%', flexGrow: 1, flexBasis: '25%' }}
                    floating
                    errored={!!errors.MiddleName}
                  >
                    <FormStyled.TextyInput
                      name="MiddleName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.MiddleName}
                      autoComplete="given-name"
                      placeholder={t('EAPetitionForm.MiddleName')}
                    />
                    <FormStyled.InputLabelText floating>
                      {t('EAPetitionForm.MiddleName')}
                    </FormStyled.InputLabelText>
                    {touched.MiddleName && errors.MiddleName && (
                      <FormStyled.InputError>{errors.MiddleName}</FormStyled.InputError>
                    )}
                  </FormStyled.InputLabel>
                )}
                <FormStyled.InputLabel
                  style={{ width: '45%', flexGrow: 1 }}
                  floating
                  errored={!!errors.LastName}
                >
                  <FormStyled.TextyInput
                    name="LastName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.LastName}
                    autoComplete="family-name"
                    placeholder={`${t('EAPetitionForm.LastName')}*`}
                  />
                  <NameSplitter
                    controller={controller}
                    namePartToCheck="LastName"
                    namePartToSplit="MiddleName"
                    position="start"
                    nameLabel={t('EAPetitionForm.MiddleName')}
                  />
                  <NameSplitter
                    controller={controller}
                    namePartToCheck="LastName"
                    namePartToSplit="Suffix"
                    position="end"
                    nameLabel={t('EAPetitionForm.Suffix')}
                  />
                  <FormStyled.InputLabelText floating>
                    {t('EAPetitionForm.LastName')}
                    <sup>*</sup>
                  </FormStyled.InputLabelText>
                  {touched.LastName && errors.LastName && (
                    <FormStyled.InputError>{errors.LastName}</FormStyled.InputError>
                  )}
                </FormStyled.InputLabel>
                {typeof values.Suffix !== 'undefined' && values.Suffix && (
                  <FormStyled.InputLabel
                    style={{ width: '25%', flexGrow: 1, flexBasis: '15%' }}
                    floating
                    errored={!!errors.Suffix}
                  >
                    <FormStyled.TextyInput
                      name="Suffix"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.Suffix}
                      autoComplete="suffix"
                      placeholder={t('EAPetitionForm.Suffix')}
                    />
                    <FormStyled.InputLabelText floating>
                      {t('EAPetitionForm.Suffix')}
                    </FormStyled.InputLabelText>
                    {touched.Suffix && errors.Suffix && (
                      <FormStyled.InputError>{errors.Suffix}</FormStyled.InputError>
                    )}
                  </FormStyled.InputLabel>
                )}
              </FormStyled.CustomFieldRow>
              <FormStyled.CustomFieldRow>
                <FormStyled.InputLabel fullWidth floating errored={!!errors.EmailAddress}>
                  <FormStyled.TextyInput
                    name="EmailAddress"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.EmailAddress}
                    autoComplete="email"
                    placeholder={`${t('EAPetitionForm.EmailAddress')}*`}
                  />
                  <FormStyled.InputLabelText floating>
                    {t('EAPetitionForm.EmailAddress')}
                    <sup>*</sup>
                  </FormStyled.InputLabelText>
                  {touched.EmailAddress && errors.EmailAddress && (
                    <FormStyled.InputError>{errors.EmailAddress}</FormStyled.InputError>
                  )}
                </FormStyled.InputLabel>
              </FormStyled.CustomFieldRow>
              {language !== 'es-MX' && (
                <FormStyled.CustomFieldRow>
                  <FormStyled.InputLabel fullWidth floating errored={!!errors.MobilePhone}>
                    <FormStyled.TextyInput
                      name="MobilePhone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.MobilePhone}
                      autoComplete="tel"
                      placeholder={t('EAPetitionForm.MobilePhone')}
                    />
                    <FormStyled.InputLabelText floating>
                      {t('EAPetitionForm.MobilePhone')}
                    </FormStyled.InputLabelText>
                    {touched.MobilePhone && errors.MobilePhone && (
                      <FormStyled.InputError>{errors.MobilePhone}</FormStyled.InputError>
                    )}
                  </FormStyled.InputLabel>
                </FormStyled.CustomFieldRow>
              )}
              <FormStyled.CustomFieldRow>
                <FormStyled.InputLabel
                  floating
                  errored={!!errors.PostalCode}
                  style={{ flexBasis: '60%' }}
                >
                  <FormStyled.TextyInput
                    name="PostalCode"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.PostalCode}
                    autoComplete="postal-code"
                    placeholder={`${t('EAPetitionForm.PostalCode')}${isUS ? '*' : ''}`}
                  />
                  <FormStyled.InputLabelText floating>
                    {t('EAPetitionForm.PostalCode')}
                    {isUS && <sup>*</sup>}
                  </FormStyled.InputLabelText>
                  {touched.PostalCode && errors.PostalCode && (
                    <FormStyled.InputError>{errors.PostalCode}</FormStyled.InputError>
                  )}
                </FormStyled.InputLabel>
                <FormStyled.InputLabel floating errored={!!errors.Country}>
                  <FormStyled.Select
                    name="Country"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.Country || ''}
                    autoComplete="country"
                    required
                  >
                    <option value="" disabled>
                      {t('EAPetitionForm.Country')}
                    </option>
                    {(suppliedOptions.table_countries && language === 'en-US'
                      ? suppliedOptions.table_countries
                      : countriesList
                    ).map(o => (
                      <option key={o.value} value={o.value}>
                        {o.display}
                      </option>
                    ))}
                  </FormStyled.Select>
                  <FormStyled.InputLabelText floating>
                    {t('EAPetitionForm.Country')}
                    <sup>*</sup>
                  </FormStyled.InputLabelText>
                  {touched.Country && errors.Country && (
                    <FormStyled.InputError>{errors.Country}</FormStyled.InputError>
                  )}
                </FormStyled.InputLabel>
              </FormStyled.CustomFieldRow>
              <FormStyled.CustomFieldRow style={{ marginTop: '1.25rem' }}>
                <FormStyled.CheckboxLabel>
                  <FormStyled.Checkbox
                    type="checkbox"
                    name="YesSignMeUpForUpdatesForBinder"
                    value={values.YesSignMeUpForUpdatesForBinder ? 1 : 0}
                    onChange={event => {
                      setFieldValue('YesSignMeUpForUpdatesForBinder', event.target.checked);
                    }}
                    onBlur={handleBlur}
                    checked={!!values.YesSignMeUpForUpdatesForBinder}
                  />
                  <FormStyled.CheckboxLabelText>
                    {t('EAPetitionForm.Consent')}
                    <Tooltip
                      content={t('EAPetitionForm.InfoConsent')}
                      teaser={t('EAPetitionForm.InfoConsent')}
                      inline
                    />
                  </FormStyled.CheckboxLabelText>
                  {touched.YesSignMeUpForUpdatesForBinder &&
                    !!errors.YesSignMeUpForUpdatesForBinder && (
                      /* eslint-enable indent */
                      <FormStyled.InputError>
                        {errors.YesSignMeUpForUpdatesForBinder}
                      </FormStyled.InputError>
                      /* eslint-disable indent */
                    )}
                </FormStyled.CheckboxLabel>
              </FormStyled.CustomFieldRow>
              {displaySmsOptIn && (
                <FormStyled.CustomFieldRow>
                  <FormStyled.CheckboxLabel>
                    <FormStyled.Checkbox
                      type="checkbox"
                      name="SmsSubscribeMobilePhone"
                      value={values.SmsSubscribeMobilePhone ? 1 : 0}
                      onChange={event => {
                        setFieldValue('SmsSubscribeMobilePhone', event.target.checked);
                      }}
                      onBlur={handleBlur}
                      checked={!!values.SmsSubscribeMobilePhone}
                    />
                    <FormStyled.CheckboxLabelText>
                      {t('EAPetitionForm.ConsentSms')}
                    </FormStyled.CheckboxLabelText>
                    {touched.SmsSubscribeMobilePhone && !!errors.SmsSubscribeMobilePhone && (
                      <FormStyled.InputError>
                        {errors.SmsSubscribeMobilePhone}
                      </FormStyled.InputError>
                    )}
                  </FormStyled.CheckboxLabel>
                </FormStyled.CustomFieldRow>
              )}
              {displayRememberMe && (
                <FormStyled.CustomFieldRow>
                  <FormStyled.CheckboxLabel>
                    <FormStyled.Checkbox
                      type="checkbox"
                      name="RememberMeAction"
                      value={rememberMeChecked ? 1 : 0}
                      onChange={handleRememberMeChange}
                      checked={rememberMeChecked}
                    />
                    <FormStyled.CheckboxLabelText>
                      Remember my details{' '}
                      <Tooltip
                        teaser="Save your address details"
                        content="Safely remember your name, email, phone number, and mailing address to take action faster next time! Your personal information will be encrypted and stored securely in your browser. Uncheck this box if you prefer to re-enter your details each time."
                        inline={true}
                      />
                    </FormStyled.CheckboxLabelText>
                  </FormStyled.CheckboxLabel>
                </FormStyled.CustomFieldRow>
              )}
              {!isUS && (
                <FormStyled.CustomFieldRow>
                  <FormStyled.CheckboxLabel>
                    <FormStyled.Checkbox
                      type="checkbox"
                      name="AcceptedPrivacyPolicy"
                      value={values.AcceptedPrivacyPolicy ? 1 : 0}
                      onChange={event => {
                        setFieldValue('AcceptedPrivacyPolicy', event.target.checked);
                      }}
                      onBlur={handleBlur}
                      checked={!!values.AcceptedPrivacyPolicy}
                    />
                    <FormStyled.CheckboxLabelText>
                      {t('EAPetitionForm.AcceptPrivacyPolicyPrefix')}{' '}
                      <Styled.Link as={Link} href={t('EAPetitionForm.AcceptPrivacyPolicyLinkUrl')}>
                        {t('EAPetitionForm.AcceptPrivacyPolicyLinkText')}
                      </Styled.Link>
                      {t('EAPetitionForm.AcceptPrivacyPolicySuffix')}
                    </FormStyled.CheckboxLabelText>
                    {touched.AcceptedPrivacyPolicy && !!errors.AcceptedPrivacyPolicy && (
                      <FormStyled.InputError>{errors.AcceptedPrivacyPolicy}</FormStyled.InputError>
                    )}
                  </FormStyled.CheckboxLabel>
                </FormStyled.CustomFieldRow>
              )}
              <FormStyled.CustomFieldRow centered marginTop="1.5rem" marginBottom="1.425rem">
                <Styled.SubmitButton>
                  <Button
                    narrow
                    big
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => {
                      handleSubmit();
                      setDisplayFormError(formHasErrors);
                      !displayFormError &&
                        rememberMeChecked &&
                        postUserData(
                          {
                            firstName: values.FirstName,
                            lastName: values.LastName,
                            address1: '',
                            postcode: values.PostalCode,
                            locality: '',
                            state: '',
                            country: values.Country,
                            email: values.EmailAddress,
                            address2: '',
                            mobilePhone: values.MobilePhone || '',
                          },
                          rememberMeChecked,
                        );
                      !rememberMeChecked && handleDeleteUserData(rememberMeChecked);
                    }}
                  >
                    {t('EAPetitionForm.SignNow')}
                  </Button>
                  {displayFormError && (
                    <FormStyled.InputError>{t('EAPetitionForm.FormError')}</FormStyled.InputError>
                  )}
                </Styled.SubmitButton>
              </FormStyled.CustomFieldRow>
            </FormStyled.Section>
          </FormStyled.Body>
          <Styled.Disclaimers>
            <PrivacyPolicyLink />
            {displaySmsOptIn && <FormSmsDisclaimer />}
          </Styled.Disclaimers>
          <br />
        </Styled.PetitionFields>
      </FormStyled.Container>
    </Styled.PetitionFormContent>
  );
};

export const EveryActionPetitionContent = ({
  formType,
  advocacyFields,
  everyActionPetitionFormData,
  suppliedOptions,
  FormContent,
}: {
  formType: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  advocacyFields: any;
  everyActionPetitionFormData: EveryActionPetitionFormComponent;
  suppliedOptions: { table_countries?: CountryList };
  FormContent: ComponentType<FormikPetitionFormProps>;
}) => {
  const isMobile = useIsMobile();

  // Get form pre-content background color
  const parentColorTheme = useContext(ColorThemeContext);
  const colorTheme = everyActionPetitionFormData.colorTheme || parentColorTheme;
  const { accentedColorTheme, formColorTheme } = getTwoToneTheming(
    everyActionPetitionFormData.formBackgroundStyle,
    colorTheme,
  );

  // Get progress bar status and set animations
  const progress = useEveryActionFormProgress(everyActionPetitionFormData.formId);
  const progressRef = useRef(null);
  const isProgressRefOnScreen = useOnScreen(progressRef, 0, 0.05, true);
  const [isProgressLoaded, setIsProgressLoaded] = useState(false);
  const hasProgressBar = ['enabled', 'placeholder'].includes(
    everyActionPetitionFormData.progressBarStatus,
  );
  const progressBarEnabled =
    everyActionPetitionFormData.progressBarStatus === 'enabled' &&
    progress.totalSubmissionProgress >= 2000;
  const progressOnScreen = hasProgressBar ? isProgressRefOnScreen : false;

  useEffect(() => {
    if (progress.loaded) {
      setIsProgressLoaded(true);
    }
  }, [progress]);

  // Compute dynamic issue text height based on other form pre-content elements
  const textAboveProgressBarRef = useRef(null);
  const formHeaderRef = useRef(null);
  const getRefHeight = ref => (ref.current ? ref.current.offsetHeight : 0);

  const issueTextHeight = isMobile
    ? DEFAULT_TRUNCATE_HEIGHT
    : `calc(100vh - ${FORM_TOP_PADDING} - ${FormStyled.FORM_NOTCH_HEIGHTS.center} - ${
        Styled.PETITION_TOP_MARGIN
      } - ${TRUNCATE_BUTTON_HEIGHT} - ${getRefHeight(textAboveProgressBarRef)}px - ${getRefHeight(
        formHeaderRef,
      )}px - ${getRefHeight(progressRef)}px)`;

  // Determine whether mobile CTA button should appear
  const formContentRef = useRef(null);
  const formContentOnScreen = useOnScreen(formContentRef, 0, 0, false);
  const textBelowProgressBarRef = useRef(null);
  const textBelowProgressBarOnScreen = useOnScreen(textBelowProgressBarRef, 0, 0.2, false);

  // Use text height to estimate whether the truncate text is collapsed, as we don't have direct access to that information
  const truncateTextCollapsed =
    getRefHeight(textBelowProgressBarRef) <= DEFAULT_TRUNCATE_HEIGHT + 70;

  // Add window resize event listener to enable dynamic issue text height to re-compute on resizing window
  const [, setWindowWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : undefined,
  );

  useEffect(() => {
    if (typeof window === 'undefined') {
      return () => {};
    }

    const handleResize = (): void => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <ColorTheme colorTheme={accentedColorTheme}>
        <FormStyled.PreContent>
          <FormStyled.Container>
            <FormStyled.Section>
              <div ref={textAboveProgressBarRef} style={{ overflow: 'auto' }}>
                <Markdown progressBarEnabled={progressBarEnabled}>
                  {everyActionPetitionFormData.textAboveProgressBar || ''}
                </Markdown>
              </div>
              {hasProgressBar ? (
                <div ref={progressRef} style={{ overflow: 'auto' }}>
                  <Progress
                    value={progress.totalSubmissionProgress}
                    valueLabel={everyActionPetitionFormData.progressBarValueLabel}
                    total={progress.loaded && progress.targetAmount}
                    totalLabel={everyActionPetitionFormData.progressBarTargetLabel}
                    isOnScreen={progressOnScreen && isProgressLoaded}
                    animationDuration={2}
                    isEnabled={progressBarEnabled}
                  />
                </div>
              ) : (
                <br />
              )}

              <Styled.IssueText ref={textBelowProgressBarRef}>
                <Truncate initialHeight={issueTextHeight} minHeight={80}>
                  <Markdown>{everyActionPetitionFormData.textBelowProgressBar || ''}</Markdown>
                </Truncate>
              </Styled.IssueText>
            </FormStyled.Section>
          </FormStyled.Container>
        </FormStyled.PreContent>
      </ColorTheme>
      <ColorTheme colorTheme={formColorTheme}>
        <div ref={formContentRef}>
          <FormContent
            formType={formType}
            advocacyFields={advocacyFields}
            everyActionPetitionFormData={everyActionPetitionFormData}
            suppliedOptions={suppliedOptions}
            formHeaderRef={formHeaderRef}
          />
        </div>
      </ColorTheme>
      {typeof document !== 'undefined' && (
        <ColorTheme colorTheme={accentedColorTheme}>
          {ReactDOM.createPortal(
            <Styled.PetitionCTA
              isShown={
                textBelowProgressBarOnScreen && !truncateTextCollapsed && !formContentOnScreen
              }
            >
              <Button onClick={() => scrollToLocation(formContentRef.current, 0, 'down')} big>
                {t('EAPetitionForm.CTAText')}
              </Button>
            </Styled.PetitionCTA>,
            getOrCreateRootIfNotExists('fixed-banner-target'),
          )}
        </ColorTheme>
      )}
    </>
  );
};
