import React, { useState, useEffect } from 'react';

import { t } from '+/utils/textDictionary';

export const SmsOptIn = ({ controller }: { controller: EveryActionModelController }) => {
  const mobilePhone = controller.getMobilePhone();
  const isUsAddressAndPhone = controller.isUsMobilePhone() && controller.isUsMailingAddress();
  const smsSubscribeMobilePhone = controller.getSmsSubscribeMobilePhone();

  const [isVisible, setIsVisible] = useState(smsSubscribeMobilePhone && isUsAddressAndPhone);

  const handleChange = () => {
    controller.setSmsSubscribeMobilePhone(!smsSubscribeMobilePhone);
  };

  useEffect(() => {
    if (!isVisible && !!mobilePhone && isUsAddressAndPhone) {
      controller.setSmsSubscribeMobilePhone(true);
    } else if ((isVisible && !mobilePhone) || !isUsAddressAndPhone) {
      controller.setSmsSubscribeMobilePhone(false);
    }
    setIsVisible(!!mobilePhone && isUsAddressAndPhone);
  }, [mobilePhone, isUsAddressAndPhone]);

  return (
    <React.Fragment>
      {isVisible && (
        <label className="at-check">
          <input
            type="checkbox"
            onChange={() => handleChange()}
            checked={smsSubscribeMobilePhone}
          />{' '}
          <span className="at-checkbox-title-container">
            <span className="at-checkbox-title">{t('Widget.SmsOptIn.Label')}</span>
          </span>
        </label>
      )}
    </React.Fragment>
  );
};
