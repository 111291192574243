export default {
  '&EAAdvocacyForm.FirstName': 'First Name',
  '&EAAdvocacyForm.LastName': 'Last Name',
  '&EAAdvocacyForm.Suffix': 'Suffix',
  '&EAAdvocacyForm.Prefix': 'Prefix',
  '&EAAdvocacyForm.MiddleName': 'Middle Name',
  '&EAAdvocacyForm.EmailAddress': 'Email Address',
  '&EAAdvocacyForm.MobilePhone': 'Mobile Phone',
  '&EAAdvocacyForm.Consent': 'Keep me updated on this and other campaigns.',
  '&EAAdvocacyForm.InfoConsent':
    "Keep this box checked if you'd like to receive email updates from The Humane League, including campaign updates and occasional action alerts. You may unsubscribe any time.",
  '&EAAdvocacyForm.AcceptPrivacyPolicyPrefix': 'I have read and accept the',
  '&EAAdvocacyForm.AcceptPrivacyPolicyLinkText': 'privacy policy',
  '&EAAdvocacyForm.AcceptPrivacyPolicyLinkUrl': 'https://thehumaneleague.org/privacy-policy',
  '&EAAdvocacyForm.AcceptPrivacyPolicySuffix': '.',
  '&EAAdvocacyForm.AcceptPrivacyPolicyErrorNotChecked':
    'You must accept the privacy policy to submit.',
  '&EAAdvocacyForm.ConsentSms': 'Send me occasional SMS alerts*',
  '&EAAdvocacyForm.InfoConsentSms':
    "Keep this box checked if you'd like to receive occasional SMS (text) alerts from The Humane League, including campaign updates and action alerts. You may opt out any time.",
  '&EAAdvocacyForm.PostalCode': 'Postal Code',
  '&EAAdvocacyForm.Country': 'Country',
  '&EAAdvocacyForm.NextStep': 'Next >',
  '&EAAdvocacyForm.PreviousStep': 'Go Back',
  '&EAAdvocacyForm.Submit': 'Send',
  '&EAAdvocacyForm.SignNow': 'SIGN NOW',
  '&EAAdvocacyForm.Subject': 'Subject',
  '&EAAdvocacyForm.Message': 'Message',
  '&EAAdvocacyForm.MessagePrefix': 'Dear [Chicken industry leader],',
  '&EAAdvocacyForm.MessageSuffix': 'Sincerely,\n %name',
  '&EAAdvocacyForm.EditYourMessage': 'Edit your message',
  '&EAAdvocacyForm.ReadOrEditYourMessage': 'READ / EDIT YOUR MESSAGE',
  '&EAAdvocacyForm.DoneReadingOrEditingYourMessage': 'DONE',
  '&EAAdvocacyForm.FormError': 'Please fix the errors marked above',
  '&EAAdvocacyForm.URLSanitized': 'Your %label cannot include a URL',
} as TextDictionary;
