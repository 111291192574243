export default {
  '&AddressLine1': 'Address',
  '&CopyBlock.Expand': 'Read More',
  '&CopyBlock.Condense': 'Read Less',
  '&FollowUpForm.DonationMessage': 'Your %amount donation was processed!',
  '&FollowUpForm.ThankYou': 'Thank You!',
  '&Images.Photo': 'Photo:',
  '&Widget.Address.AddressLine1': 'Mailing Address',
  '&Widget.Address.AddressLine2': 'Apartment, unit, suite, or floor # (optional)',
  '&Widget.Address.A11yDescription':
    'Begin typing your address. When autocomplete results are available, use up and down arrows to review and enter to select.',
  '&Widget.Address.A11yResultsAvailable': 'Address autocomplete results available.',
  '&Widget.AmountOptions.CustomLabel': 'Other',
  '&Widget.AmountOptions.A11yCustomCloseLabel': 'Cancel custom amount',
  '&Widget.AmountOptions.A11yCustomLabel': 'Custom donation amount',
  '&Widget.AmountOptions.InHonor': 'I’m giving in honor of a friend or loved one.',
  '&Widget.AmountOptions.MinError': 'Minimum donation is %currency%min',
  '&Widget.AmountOptions.MaxError': 'Maximum donation is %currency%max',
  '&Widget.MendedHeartSocietyAmountOptions.CustomLabel': 'Other Amount',
  '&Widget.Badges.A11yHeading': 'Accreditation',
  '&Widget.CurrencySelector.Label': 'Select Currency',
  '&Widget.CurrencySelector.Bitcoin': 'Crypto',
  '&Widget.CurrencySelector.CAD': '$CAD',
  '&Widget.CurrencySelector.EUR': '€EUR',
  '&Widget.CurrencySelector.GBP': '£GBP',
  '&Widget.CurrencySelector.USD': '$USD',
  '&Widget.FormHeader.AmountPreText': 'You are giving:',
  '&Widget.FormHeader.Monthly': 'monthly',
  '&Widget.FormHeader.Change': 'change',
  '&Widget.FormHeader.A11yChangeLabel': 'Change donation amount',
  '&Widget.FormNavigation.Back': 'Back',
  '&Widget.FormErrors.AuthPayment': 'You must authorize this payment method to continue.',
  '&Widget.FormErrors.MissingFields': 'Please fix the fields marked below to continue.',
  '&Widget.FrequencySelector.GiveMonthly': 'Give Monthly',
  '&Widget.FrequencySelector.GiveOnce': 'Give Once',
  '&Widget.FrequencySelector.UpsellText': 'A monthly gift can help animals in need all year long.',
  '&Widget.FrequencySelector.UpsellCompleteText':
    'Your monthly gift will help animals all year long!',
  '&Widget.Headings.ContactInformation': 'Contact Information',
  '&Widget.Headings.CreditCardOrBank': 'Donate by Credit Card or Bank Payment',
  '&Widget.Headings.MailingAddress': 'Mailing Address',
  '&Widget.InitialContinueButton.ButtonText': 'Next',
  '&Widget.NameSplitter.No': 'No',
  '&Widget.NameSplitter.QuestionStart': 'Is',
  '&Widget.NameSplitter.QuestionEnd': 'your %name?',
  '&Widget.NameSplitter.Yes': 'Yes',
  '&Widget.NextStep.ButtonText': 'Next',
  '&Widget.NextStep.SecurityTagline': 'Your donation is secured with 256-bit encryption.',
  '&Widget.PaymentMethodSelector.PayWith': 'Pay With',
  '&Widget.PaymentMethodSelector.Bank': 'Bank',
  '&Widget.PaymentMethodSelector.CreditCard': 'Credit Card',
  '&Widget.PaymentMethodSelector.ApplePay': 'ApplePay',
  '&Widget.PaymentMethodSelector.BankOrCredit': 'Bank account or credit card',
  '&Widget.PaymentMethodSelector.PayPal': 'PayPal',
  '&Widget.PaymentMethodSelector.Selected': 'Selected payment method',
  '&Widget.QuantityDisplayTab.Text': 'Your donation: ',
  '&Widget.SmsOptIn.Label': 'Sign me up for SMS updates*',
  '&Widget.SmsOptIn.Tooltip.PhoneTeaser': 'What’s this?',
  '&Widget.SmsOptIn.Tooltip.PhoneContent':
    'Keep this box checked if you’d like to receive occasional SMS (text) alerts from The Humane League, including campaign updates and action alerts. You may opt out any time.',
  '&Widget.EmailOptIn.Tooltip.Teaser': 'What’s this?',
  '&Widget.EmailOptIn.Tooltip.Content':
    'We will send a donation confirmation to your email address. We may also email you if there are any problems processing your gift. If you’d like to receive regular email updates, you may opt in by checking this box.',
  '&Widget.Tooltip.AddressTeaser': 'Why do we need this?',
  '&Widget.Tooltip.AddressContent':
    'Your address is used to verify your identity for your payment processing. If you live in the US, we will also send you a physical thank you letter that is a suitable tax document.',
  '&Widget.ViewDisclosures': 'View disclosures',
  '&Upsell.Title': 'Make It Monthly',
  '&Upsell.Error': 'Amount must be between %cur%min and %cur%max',
  '&Upsell.Ask':
    'Will you join hundreds of other monthly supporters to help animals all year long?',
  '&Upsell.Yes': '**Yes!** Please change my one-time gift into a monthly gift:',
  '&Upsell.MonthlyButtonLabel': 'Give Monthly',
  '&Upsell.Decline': 'No, Give once',
  '&Upsell.Countdown': 'Your one-time donation will be processed in %seconds seconds...',
  '&Widget.SubmitButton.Submit': 'Give %amount',
  '&Widget.SubmitButton.Once': 'now',
  '&Widget.SubmitButton.Monthly': '/mo',
  '&Widget.SubmitButton.MonthlyLabel': 'per month',
  '&Widget.InHonorOrInMemoryOf.Honor': 'In honor of',
  '&Widget.InHonorOrInMemoryOf.Memory': 'In memory of',
  '&Widget.InHonorOrInMemoryOf.HonoreeName': 'Honoree Name',
  '&Widget.InHonorOrInMemoryOf.IncludeRecipient':
    'I’d like to notify someone of this contribution.',
  '&Widget.TransactionFeeCheckbox.Label':
    'I’d like to help cover the transaction fees for my gift.',
  '&Widget.TransactionFeeCheckbox.NewAmount': 'My total amount will be',
  '&Widget.TransactionFeeCheckbox.Monthly': '/month',
  '&Widget.FastAction.Copy': 'Take future action with a single click.',
  '&Optional': 'Optional',
  '&EmailDisclaimer':
    'By submitting your email address, you agree to receive email from The Humane League, including a monthly newsletter and exciting updates on THL’s campaigns and programs.',
  '&PerMonth.Full': '/month',
  '&PerMonth.Abbreviation': '/mo',
  '&CustomForm.ThankYou': 'Thank you for your response!',
  '&Widget.RememberMe.Tooltip.Teaser': 'Save your address details',
  '&Widget.RememberMe.Tooltip.Content':
    'Safely remember your name, email, phone number, and mailing address to make donating faster next time! Your personal information will be encrypted and stored securely in your browser. Uncheck this box if you prefer to re-enter your details each time.',
} as TextDictionary;
