import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ stacked?: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 1.5rem 0 0 0;
  align-items: center;
  justify-content: center;

  ${props => {
    if (props.stacked) {
      return css`
        flex-direction: column;
      `;
    }
  }}
`;

export const NextButtonWrapper = styled.div`
  order: 2;
  margin: 0;
`;

export const BackButtonWrapper = styled.div<{ stacked?: boolean }>`
  order: 1;
  margin: 0 2rem 0 0;

  ${props => {
    if (props.stacked) {
      return css`
        order: 4;
        margin: 0;
      `;
    }
  }}
`;

export const SecurityWrapper = styled.div`
  order: 3;
  width: 100%;
  margin: 1.25rem 0 2.5rem;
  color: var(--color-neutral-600);
  font: italic 0.875rem var(--font-primary);
`;

export const Disclaimers = styled(SecurityWrapper)`
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
  line-height: 1.25;
  text-align: center;

  a {
    color: inherit;

    &:hover,
    &:focus-visible {
      color: var(--color-neutral-800);
    }

    &:active {
      color: var(--color-black);
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    --offset: -2rem;

    margin-inline: var(--offset);
    width: calc(100% + calc(var(--offset) * -2));
  }
`;
