import styled, { css, keyframes } from 'styled-components';

import { Tag, ActionTag } from '../Tag/Tag.styles';
import { Truncate, Button as TruncateButton } from '../Truncate/Truncate.styles';
import { Tooltip } from '../Tooltip/Tooltip.styles';

import { Markdown as MarkdownComponent } from '+/components/Markdown';
import { textH1, textH2, textH3, textH4, italicH5 } from '+/styles/reusable/textStyles';
import {
  CustomFieldRow,
  Header,
  Content,
  FORM_NOTCH_HEIGHTS,
} from '+/styles/styledComponents/Form.styles';

export const PETITION_TOP_MARGIN = `calc(-${FORM_NOTCH_HEIGHTS.center} + 3.5rem)`;

const tagAnimateIn = keyframes`
  0%, 40% {
    transform: translateX(calc(-75%));
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const Markdown = styled(MarkdownComponent)<{ progressBarEnabled?: boolean }>`
  text-align: left;

  p:not(:last-child) {
    margin-bottom: 0;
  }

  h1:not(:last-child) {
    margin-bottom: 1.25rem;

    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      margin-bottom: 2.18rem;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    strong {
      color: var(--color-theme-accent);
    }
  }

  h1 {
    ${textH1}
    position: relative;
    line-height: 1.25;
    font-size: clamp(var(--font-size-xl), 1.5rem + 1.93vw, var(--font-size-xxxl));
    letter-spacing: -0.02em;
    text-transform: none;

    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      ${textH1}
      text-transform: none;
      letter-spacing: -0.02em;
    }
  }

  h2 {
    ${textH2}
    font-size: 2.1rem;
    letter-spacing: -0.02em;

    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      ${textH2}
      letter-spacing: -0.02em;
      max-width: 36rem;
      padding-top: 0.7rem;
    }
  }

  h3 {
    ${textH3}
  }

  h4 {
    ${textH4}
  }

  h5 {
    ${italicH5}
    letter-spacing: 0;
    font-size: 1.35rem;

    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      letter-spacing: -0.01em;
      font-size: 1.43rem;
      line-height: calc(0.9em + 0.85rem);
    }
  }

  button {
    margin: 0 auto;
  }

  ${Tag} {
    animation: ${tagAnimateIn} 1s ease-in-out;
  }

  ${ActionTag} {
    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      display: ${props => (props.progressBarEnabled ? 'none' : '')};
    }
  }
`;

export const IssueText = styled.div`
  ${Truncate} {
    p {
      margin-bottom: 1rem;

      @media (min-width: ${props => props.theme.breakpoints.lg}) {
        margin-bottom: 1.875rem;
      }
    }

    p:last-child {
      margin-bottom: 2.5rem;

      @media (min-width: ${props => props.theme.breakpoints.lg}) {
        margin-bottom: 3.5rem;
      }
    }
  }

  ${Markdown} {
    overflow: hidden;
  }

  button:not(${TruncateButton}) {
    background: var(--color-theme-accent);
    color: var(--color-theme-inverse);
  }
`;

export const Link = styled.a`
  color: inherit;

  &:hover,
  &:focus-visible {
    color: var(--color-neutral-800);
  }

  &:active {
    color: var(--color-black);
  }
`;

export const PetitionFormContent = styled(Content)`
  margin-top: calc(-${FORM_NOTCH_HEIGHTS.centerMobile} + 3rem);

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    margin-top: ${PETITION_TOP_MARGIN};
  }
`;

export const PetitionFormHeader = styled(Header)`
  margin-bottom: 1.5rem;
`;

export const PetitionFields = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 38rem;

  input {
    background: var(--color-white);

    &::placeholder {
      color: #777c80;
      transition: color 0.1s;
    }
  }

  select {
    background: var(--color-white) var(--image-select-arrow) no-repeat right 0.75em center;
  }

  input,
  select {
    &:focus,
    &:focus-visible,
    :root &:focus-visible {
      border-bottom-color: var(--color-theme-accent);
    }
  }

  ${CustomFieldRow} {
    padding-bottom: 0.75rem;
  }

  ${Tooltip} {
    button {
      color: #8d8f92;

      &:hover {
        color: var(--color-neutral-450);
      }
    }
  }
`;

export const PetitionCTA = styled.div<{ isShown: boolean }>`
  display: flex;
  justify-content: center;
  background: rgba(29, 37, 44, 0.75);
  padding: 1.5rem;
  transition: transform 0.6s ease-in-out, opacity 0.6s;

  > button {
    width: 100%;
    max-width: 23rem;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    display: none;
  }

  ${props =>
    props.isShown
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
          transform: translateY(100%);
        `}
`;

export const SubmitButton = styled.div`
  text-align: center;
  width: 100%;

  button {
    font-size: 1.35rem;
    height: 4.5rem;
    width: 100%;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    width: auto;
    button {
      font-size: 1.22rem;
      height: 4rem;
    }
  }
`;

export const Disclaimers = styled.div`
  font-family: var(--font-primary);
  color: var(--color-theme-base-inverse);
  text-align: center;
  padding-inline: var(--form-side-padding);
  display: flex;
  flex-direction: column;
  gap: 1rem;

  > * {
    font-size: 0.75rem;
  }

  a {
    color: inherit;

    &:hover,
    &:focus-visible {
      color: var(--color-neutral-800);
    }

    &:active {
      color: var(--color-black);
    }
  }
`;
