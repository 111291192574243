import React from 'react';

import { Link } from '+/components/Link';

export const FormSmsDisclaimer = () => (
  <div>
    * By opting to receive SMS alerts, you are agreeing to receive text messages from The Humane
    League, including action alerts and campaign updates. Up to 8 msgs/month. You may opt out at any
    time. Message and data rates may apply. Text HELP for more information. Text STOP to stop
    receiving messages.{' '}
    <Link href="https://thehumaneleague.org/terms-and-conditions" target="_blank">
      Terms & Conditions.
    </Link>
  </div>
);
